import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Wahn: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Wahn,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Wahn"
          d="M896,946l4,56l-26,10c-30.8-36.7-23.2-28.9-34-74l20,2l2-8h12l4,10h19.2L896,946z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 857.9625 975.401)"
        >
          Wahn
        </text>
      </g>
    </>
  );
});
